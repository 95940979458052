import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
export const forgetpass = createAsyncThunk("forget", async (email) => {
  try {
    const response = await axios.post(
      "https://awvtracker.247healthmedpro.com/api/unsecure/user/forgotpassword",
      {
        email: email,
      }
    );
    if (response) {
      message.success("Password reset email sent successfully");
    }
  } catch (error) {
    throw Error("Failed to fetch data");
  }
});
const forgetSlice = createSlice({
  name: "forget",
  initialState: {},
  reducers: {},
});
export default forgetSlice.reducer;
