import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
// export const FormSlice = createAsyncThunk("forget", async (formdat) => {
//   try {
//     const token = localStorage.getItem("token");
//     const response = await axios.post(
//       "https://awvtracker.247healthmedpro.com/api/medicare/save",
//       formdat,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     if (response) {
//       message.success("Form submitted successfully!");
//     }
//   } catch (error) {
//     throw Error("Failed to fetch data");
//   }
// });
export const FormSlice = createAsyncThunk("forget", async (formdat) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      "https://awvtracker.247healthmedpro.com/api/medicare/save",
      formdat,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response) {
      message.success("Form submitted successfully!");
    }
    return { data: response.data, message: "Form submitted successfully!" };
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
      throw Error(error.response.data.message);
    } else {
      message.error("Failed to submit form");
      throw Error("Failed to submit form");
    }
  }
});

const formSlice = createSlice({
  name: "forget",
  initialState: {},
  reducers: {},
});
export default formSlice.reducer;
