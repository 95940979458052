import * as XLSX from "xlsx";
import { Option } from "antd/es/mentions/index.js";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import "./styles.css";
import { Checkbox, DatePicker, Modal, Progress, Select, Spin, message } from "antd";
import { Button } from "antd";
import { EditOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { Link, unstable_HistoryRouter, useNavigate } from "react-router-dom";
import SvgIcon from "./svgIcon";

const Header = () => {
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [progress, setProgress] = useState();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [emailValue1, setEmailValue1] = useState("");
  const [facilityValue1, setFacilityValue1] = useState("");
  const [dateValue1, setDateValue1] = useState("");
  const [loadings, setLoadings] = useState(false);
  const [futureAWVDate, setFutureAWVDate] = useState("");

  const showModal = () => {
    setOpenPop(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpenPop(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpenPop(false);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  useEffect(() => {
    let isMounted = true;

    if (progress > 0) {
      const intervalId = setInterval(() => {
        if (progress >= 99) {
          clearInterval(intervalId);
          if (isMounted) {
            setProgress(0);
          }
        } else {
          if (isMounted) {
            setProgress(progress + 1);
          }
        }
      }, 500);
      return () => {
        clearInterval(intervalId);
        isMounted = false;
      };
    }
  }, [progress]);

  const handleUpload = async () => {
    if (!file) {
      return;
    }
    try {
      const formData = new FormData();
      const token = localStorage.getItem("token");
      formData.append("file", file);
      setProgress(1);
      const response = await axios.post(
        "https://awvtracker.247healthmedpro.com/api/medicare/fileupload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response && response.data && response.data.success) {
        setProgress(100);
        message.success(
          response.data.message || "Form submitted successfully!"
        );
        console.log(response, "response");
      } else {
        setProgress(-1);
        message.error(
          response.data.message || "Error uploading file. Please try again."
        );
      }
    } catch (error) {
      setProgress(-1);
      if (error.response && error.response.data) {
        message.error(
          error.response.data.message ||
            "Error uploading file. Please try again."
        );
      } else {
        message.error("Error uploading file. Please try again.");
      }
    }
  };

  useEffect(() => {
    if (progress >= 100) {
      setTimeout(() => {
        setProgress(0);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }, 3000);
    }
  }, [progress]);
  const handilelogout = () => {
    localStorage.removeItem("token");
    navigate("/main");
    message.success("Logout successful");
  };

  const onChange = (e) => {
    if (e.target.checked) {
      const currentDate = new Date();
   
      const formattedDate123 = currentDate.toISOString().split('T')[0];
      const futuredateend = formattedDate123 + "T00:00:00.00Z";



      setFutureAWVDate(futuredateend);
    } else {
      setFutureAWVDate("");
    }
  };

  const downloadExcel = async () => {
   
    // const isoString = dateValue1 ? dateValue1 + "T00:00:00.00Z" : "";
    const isoString = dateValue1 ? moment(dateValue1, "MM/DD/YYYY").format("YYYY-MM-DD"):"" ;
    const workedDateValue = isoString ? isoString+ "T00:00:00.00Z" : ""
console.log(workedDateValue,"isoStringisoStringisoString")
    try {
      setLoadings(true); 
      const token = localStorage.getItem("token");
      const encodedFacilityName = encodeURIComponent(facilityValue1);
      const response = await fetch(
        `https://awvtracker.247healthmedpro.com/api/medicare/Export?facility=${encodedFacilityName}&email=${emailValue1}&workDate=${
          workedDateValue ? workedDateValue : ""
        }&futureAwvDate=${futureAWVDate}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response) {
        const jsonData = await response.json();
        const ws = XLSX.utils.json_to_sheet(jsonData.record);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
        XLSX.writeFile(wb, "facility_records.xlsx");
        window.location.reload();
      } else {
        throw new Error("Failed to send facility name to the backend");
      }
      //  }
    } catch (error) {
      message.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const downloadCsv = async () => {
    // const date = moment(dateValue1, "YYYY-MM-DD");
    // const isoString = date.add(1, "day").startOf("day").toISOString();
    // const isoString = dateValue1 + "T00:00:00.00Z";
    
    const isoString = dateValue1 ? moment(dateValue1, "MM/DD/YYYY").format("YYYY-MM-DD"):"" ;
    const workedDateValue = isoString ? isoString+ "T00:00:00.00Z" : ""
console.log(workedDateValue,"isoStringisoStringisoString")

  

    try {
      setLoadings(true);
      const token = localStorage.getItem("token");
      const encodedFacilityName = encodeURIComponent(facilityValue1);
      const response = await fetch(
        `https://awvtracker.247healthmedpro.com/api/medicare/Export?facility=${encodedFacilityName}&email=${emailValue1}&workDate=${
          workedDateValue ? workedDateValue : ""
        }&futureAwvDate=${futureAWVDate}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        const jsonData = await response.json();
        const csvContent =
          "data:text/csv;charset=utf-8," +
          encodeURIComponent(convertJsonToCsv(jsonData.record));
        const downloadLinkCsv = document.createElement("a");
        downloadLinkCsv.href = csvContent;
        downloadLinkCsv.download = "facility_records.csv";
        document.body.appendChild(downloadLinkCsv);
        downloadLinkCsv.click();
        document.body.removeChild(downloadLinkCsv);
        window.location.reload();
      } else {
        throw new Error("Failed to send facility name to the backend");
      }
    } catch (error) {
      message.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  };
  const convertJsonToCsv = (jsonArray) => {
    const header = Object.keys(jsonArray[0]).join(",");
    const rows = jsonArray.map((obj) => Object.values(obj).join(","));
    return header + "\n" + rows.join("\n");
  };

  const handleChangeExport = (value) => {
    if (value && value.value === "Excel") {
      downloadExcel();
      setEmailValue1("");
      setFacilityValue1("");
      setDateValue1("");
    } else {
      if (value && value.value === "CSV") {
        downloadCsv();
        setEmailValue1("");
        setFacilityValue1("");
        setDateValue1("");
      }
    }
  };

  return (
    <div>
      <div className="mainHeader">
        <h1>AWV Tracker</h1>
        <h1 style={{ marginTop: "2px", marginLeft: "644px" }}>Upload form</h1>
      </div>
      <div className="popupMain" >
        <div className="popup"  onClick={showModal} style={{cursor:"pointer"}}>
          <SvgIcon onClick={showModal} />
         
        </div>
        <Modal
            open={openPop}
            title="Download"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Select
                labelInValue
                defaultValue={{
                  value: "Export",
                  label: "Export",
                }}
                style={{
                  width: 100,
                }}
                className="dropDown"
                onChange={handleChangeExport}
              >
                <Option className="dropDown" value="Excel">
                  Excel
                </Option>
                <Option value="CSV">Csv</Option>
              </Select>,
            ]}
          >
            <div style={{ padding: "6px" }}>
              <label htmlForm="email">Email</label>
              <input
                type="text"
                id="email"
                name="email"
                className="inputtype"
                value={emailValue1}
                onChange={(e) => setEmailValue1(e.target.value)}
                style={{ borderRadius: "10px", marginLeft: "36px" }}
              />
            </div>
            <div style={{ padding: "6px" }}>
              <label htmlForm="facility">Facility</label>
              <input
                type="text"
                id="facility"
                name="facility"
                className="inputtype"
                value={facilityValue1}
                onChange={(e) => setFacilityValue1(e.target.value)}
                style={{ borderRadius: "10px", marginLeft: "29px" }}
              />
            </div>
            <div style={{ padding: "6px" }}>
              <label htmlForm="date">Date</label>
              {/* <input
                type="date"
                id="date"
                name="date"
                className="inputtype"
                value={dateValue1}
                onChange={(e) => setDateValue1(e.target.value)}
                style={{ borderRadius: "10px", marginLeft: "42px" }}
              /> */}
              <label htmlFor="date">Date</label>
              {/* <DatePicker
  value={dateValue1 ? moment(dateValue1, "YYYY-MM-DD") : null}
  onChange={(date, dateString) => setDateValue1(dateString)}
  style={{ borderRadius: "10px", marginLeft: "42px" }}
  placeholder="Select Date"
  format="MM/DD/YYYY"
/> */}
<DatePicker
    value={dateValue1 ? moment(dateValue1, "MM/DD/YYYY") : null}
    onChange={(date, dateString) => setDateValue1(dateString)}
    style={{ borderRadius: "10px", marginLeft: "16px", border:"1px solid #035e7b", width:"37%", height:"32px" }}
    placeholder="MM/DD/YYYY"
    format="MM/DD/YYYY"
/>
            </div>

            <div>
              {" "}
              <Checkbox onChange={onChange}>
                Future Preventive Condition Date
              </Checkbox>
            </div>

            {loadings && <Spin />}
          </Modal>
        <div>
          <form action="/action_page.php" style={{ height: "36px" }}>
            <input
              type="file"
              id="myFile"
              name="filename"
              placeholder="Upload PDF"
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{marginRight:"12px"}}
            />
            <button
              type="button"
              className="btn btn-outline py-1 px-3 custom-btn"
              height="90px"
              onClick={handleUpload}
            >
              Submit
            </button>
          </form>
          {progress > 0 && (
            <Progress
              percent={progress}
              status="active"
              style={{ marginTop: "9px", height: "20px", color: "#ffffff" }}
            />
          )}
        </div>
        <div
          style={{ display: "flex", flexDirection: "row", marginRight: "20px" }}
        >
          <div
            style={{
              fontSize: "30px",
              margin: "8px",
              border: "1px solid white",
              padding: "2px",
              marginTop: "0px",
              height: "40px",
            }}
          >
            <Link to="/edit">
              <EditOutlined />
            </Link>
          </div>
          <div>
            <Button
              type="primary"
              onClick={handilelogout}
              icon={<LeftCircleOutlined />}
              size={"large"}
              style={{ background: "white", color: "black" }}
            >
              Logout
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
